html, body {
  height: 100%;
  background-color: #F3F2EE;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  margin-right: 20px;
  color: #999;
}

a:visited {
  color:#999;
}

a:hover {
  color: #666;
}

.footer {
  position: absolute;
  bottom: 5%;
  color: #999;
}

.App {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
}

.App-logo {
  display: block;
  width: auto;
  max-height: 90%;
  height: auto;
}
